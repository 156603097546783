import React from 'react'
import Layout from '../components/layout'
import OTP from '../components/otp/component'

const OTPPage = props => (
  <Layout>
    <OTP {...props} />
  </Layout>
)

export default OTPPage

import $ from 'jquery'
import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import Logo from './../../assets/images/logo.png'
import Search from './../../assets/images/search.svg'
import OtpBackArrow from './../../assets/images/noun_back.svg'
import axios from 'axios'
import { message } from 'antd'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import { HOSTNAME } from '../config'
const cookies = new Cookies()
let initialState = {
  accessCode: '',
  err: {},
  resMessage: '',
  resType: '',
  isLoading: false,
}
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { location } = this.props
    if (!location.state || !location.state.userData) {
      navigate('/login')
    } else {
      setTimeout(function() {
        document.title = 'OTP | American Field'
        let meta = document.getElementsByTagName('meta')
        for (var i = 0; i < meta.length; i++) {
          if (meta[i].name.toLowerCase() === 'description') {
            meta[i].content = 'OTP | American Field'
          }
          if (meta[i].name.toLowerCase() === 'url') {
            meta[i].content = 'https://app.shopaf.co/otp'
          }
        }
      }, 300)
    }
  }

  handleChange(e) {
    if (e.target.name === 'accessCode') {
      this.state.err.accessCode = ''
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  submitForm(event) {
    const { location } = this.props
    let userData = _.get(location.state, 'userData', {})
    event.preventDefault()
    const { accessCode } = this.state
    let self = this
    const err = {}
    if (accessCode === '' || accessCode.trim() === '') {
      err.accessCode = 'Code is required'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      window &&
        window.ga &&
        window.ga('send', 'event', {
          eventCategory: 'OTP',
          eventAction: 'OTP Submit Clicked',
          eventLabel: 'OTP Submit Clicked',
          transport: '1',
        })
      this.setState({ isLoading: true })
      let obj = {}
      obj.email = userData.email
      obj.accessCode = accessCode
      axios
        .post(HOSTNAME + 'checkOtp', obj)
        .then(function(response) {
          let res = response.data
          if (res.success) {
            self.setState(initialState)
            self.setState({
              resMessage: res.message,
              resType: 'alert alert-success',
            })
            let expires = new Date()
            expires.setHours(expires.getHours() + 24)
            expires = new Date(expires)
            cookies.set('user', res.data, { path: '/', expires })

            let navigatePath = '/'
            const brandDetail = cookies.get('branddetail')
            if (brandDetail) {
              navigatePath = brandDetail.url
            }
            setTimeout(function() {
              navigate(navigatePath)
              cookies.remove('branddetail')
              document.cookie = 'branddetail =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
            }, 200)
          } else {
            self.setState({
              isLoading: false,
              resMessage: res.message,
              resType: 'alert alert-danger',
            })
            setTimeout(function() {
              self.setState({ resMessage: '', resType: '' })
            }, 2000)
          }
        })
        .catch(function(error) {
          self.setState({
            isLoading: false,
            resMessage: error,
            resType: 'alert alert-danger',
          })
        })
    }
  }

  resendCode(event) {
    window &&
      window.ga &&
      window.ga('send', 'event', {
        eventCategory: 'Resend OTP',
        eventAction: 'Resend OTP Clicked',
        eventLabel: 'Resend OTP Clicked',
        transport: '1',
      })
    let self = this
    const { location } = this.props
    let userData = _.get(location.state, 'userData', {})
    let obj = {}
    if (userData.loginType === 'phone') {
      obj.phoneNumber = userData.phoneNumber
    } else {
      obj.email = userData.email
    }
    obj.loginType = userData.loginType
    obj.name = userData.firstName + ' ' + userData.lastName
    this.setState({ isLoading: true })
    axios
      .post(HOSTNAME + 'resendCode', obj)
      .then(function(response) {
        let res = response.data
        if (res.success) {
          self.setState({
            isLoading: false,
            resMessage: res.message,
            resType: 'alert alert-success',
          })
          setTimeout(function() {
            self.setState({ resMessage: '', resType: '' })
          }, 10000)
        } else {
          self.setState({
            isLoading: false,
            resMessage: res.message,
            resType: 'alert alert-danger',
          })
          setTimeout(function() {
            self.setState({ resMessage: '', resType: '' })
          }, 2000)
        }
      })
      .catch(function(error) {
        self.setState({
          isLoading: false,
          resMessage: error,
          resType: 'alert alert-danger',
        })
      })
  }

  render() {
    const { accessCode, err, resMessage, resType, isLoading } = this.state
    const { location } = this.props
    let userData = _.get(location.state, 'userData', {})
    return (
      <div>
        <div className="login__page otp__page">
          <div className="vertical__align-center">
            <div className="form">
              <Link to={'/login'}>
                <div className="back__arrow">
                  <img src={OtpBackArrow} />
                </div>
              </Link>
              <div className="logo_box text-center">
                <h2 className="form_title">Enter Code</h2>
                <p className="otp_text">
                  We just sent you a text with a code, please enter it here to
                  log into account.
                </p>
              </div>
              <div className="form__inner">
                <div className="row">
                  <div className="col-md-12">
                    <FormGroup>
                      <Label for="exampleEmail">ENTER 4 DIGIT CODE</Label>
                      <Input
                        type="text"
                        name="accessCode"
                        value={accessCode}
                        onChange={this.handleChange}
                        placeholder=""
                      />
                      <FormFeedback>
                        {'accessCode' in err ? err.accessCode : ''}{' '}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </div>
              </div>
              {resMessage !== '' && (
                <div className={resType}>
                  <strong>{resMessage}</strong>
                </div>
              )}
              <Button
                className="btn btn-primary btn-block btn-lg signup_btn"
                onClick={this.submitForm.bind(this)}
              >
                SUBMIT
              </Button>
              <Button
                className="btn btn-block btn-lg resend_code"
                onClick={this.resendCode.bind(this)}
              >
                Resend Code
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
